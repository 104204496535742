import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  Pagination,
  ReferenceManyField,
} from 'react-admin';

const useStyles = makeStyles(theme => ({}));

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + rp : rp;
};

const AddPoint = ({ record }) => {
  return (
    <Typography style={{ color: '#16A085' }}>
      {record.type_id === 'PEMBELIAN'
        ? '+ ' + record.point.replace('-', '')
        : '+ 0'}{' '}
      Points
    </Typography>
  );
};

const RedemPoint = ({ record }) => {
  return (
    <Typography style={{ color: '#EB5757' }}>
      {record.type_id !== 'PEMBELIAN'
        ? '- ' + record.point.replace('-', '')
        : '- 0'}{' '}
      Points
    </Typography>
  );
};

const ListHistory = ({ data, id }) => {
  const classes = useStyles();
  return (
    <>
      <List
        {...data}
        actions={false}
        pagination={false}
        bulkActionButtons={false}>
        <ReferenceManyField
          target="customer_id"
          sort={{ field: 'timestamp', order: 'ASC' }}
          reference="point_transactions"
          filter={{ customer_id: id }}
          pagination={<Pagination />}
          perPage={10}>
          <Datagrid>
            {/* <FunctionField
              label="Current Points"
              render={record => `${record.past_point} Points`}
              style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                display: 'block',
              }}
            /> */}
            <FunctionField
              label="Date"
              render={record =>
                `${record.tgl_trasaction} ${record.time_trasaction}`
              }
            />
            {/* <FunctionField
              label="Additional Points"
              render={record => <AddPoint record={record} />}
            /> */}
            <FunctionField
              label="Point Usage"
              render={record => <RedemPoint record={record} />}
            />
            <FunctionField
              label="Total Price"
              render={record => (
                <NumberFormat number={record.gross_value} curency="Rp " />
              )}
            />
          </Datagrid>
        </ReferenceManyField>
      </List>
    </>
  );
};

export default ListHistory;
