import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  Pagination,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { useHistory } from 'react-router';
import noImage from '../../assets/no-image.jpg';
import pointProfile from '../../assets/pointsProfile.png';
import profileBg from '../../assets/profileHeader.png';
import ListHistory from '../customers/listHistory';
import ListRedem from '../customers/listRedem';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },

  headerContent: {
    marginLeft: '-16px',
    marginTop: '-56px',
    paddingTop: '65px',
    paddingRight: '16px',
    paddingLeft: '16px',
    marginRight: '-16px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center -35px',
  },
  avatarWrapper: {
    padding: theme.spacing(6),
    fontSize: '48px',
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.textcolor.main,
    fontSize: '24px',
    lineHeight: '42px',
  },
  subtitle: {
    color: theme.palette.textcolor.main,
    fontSize: '18px',
    lineHeight: '25px',
  },
  textHeader: {
    color: theme.palette.secondarytext.main,
    fontSize: '11px',
    lineHeight: '24px',
    position: 'relative',
  },
  content: {
    marginTop: theme.spacing(3),
    minHeight: '400px',
    border: '2px solid #cccc',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  barTabCustom: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '8px 8px 0 0',
    position: 'relative',
  },
  fontCapitalize: {
    textTransform: 'capitalize',
  },
  btnPassword: {
    position: 'absolute',
    color: '#7F8C8D',
    fontSize: '14px',
    top: '5px',
    right: '5px',
    textTransform: 'capitalize',
  },
  btnExp: {
    position: 'absolute',
    color: '#16A085',
    fontSize: '14px',
    top: '5px',
    right: '150px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#16A085',
      color: '#fff',
    },
  },
  contentText: {
    color: theme.palette.secondarytext.main,
    fontSize: '20px',
    lineHeight: '25px',
  },
  boxPadding: {
    paddingTop: theme.spacing(2),
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleProduct: {
    color: theme.palette.textcolor.main,
    fontSize: '14px',
    lineHeight: '24px',
  },
  textBold: {
    color: theme.palette.textcolor.main,
    fontSize: '18px',
    lineHeight: '24px',
    fontweight: 700,
  },
  textItems: {
    color: '#858585',
    fontSize: '14px',
    lineHeight: '24px',
    fontweight: 700,
  },
  dashTopBorder: {
    borderTop: '2px dashed #858585',
  },
}));

const DialogTitle = props => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.dialogTitle}
      {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const NumberFormat = ({ number, curency }) => {
  let number_string = number.toString().replace(/[^,\d]/g, ''),
    split = number_string.split(','),
    over = split[0].length % 3,
    rp = split[0].substr(0, over),
    thousand = split[0].substr(over).match(/\d{3}/gi);
  if (thousand) {
    let separator = over ? '.' : '';
    rp += separator + thousand.join('.');
  }

  return curency !== undefined ? curency + rp : rp;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3} style={{ paddingBottom: '60px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const DetailCustomer = ({ id }) => {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/customers/fn/detail/${id}`
  );

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        Personal Data
      </Typography>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Nama</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? data.name : <Skeleton />}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Level</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error && data.level_id != null ? (
              data.level_id.replace(/_/g, ' ')
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Area</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? data.area : <Skeleton />}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>NIK</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? data.nik : <Skeleton />}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Tanggal Lahir</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? data.tgl_lahir : <Skeleton />}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Jenis Kelamin</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? (
              data.gender.toLowerCase() === 'male' ? (
                'Pria'
              ) : (
                'Perempuan'
              )
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="h4"
        className={classes.title}
        style={{ marginTop: '24px' }}>
        Contact
      </Typography>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Email</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? data.email : <Skeleton />}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>No. Hp</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? data.phone : <Skeleton />}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Alamat</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? data.address : <Skeleton />}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Wa Group</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? (
              data.wa_grup ? (
                data.wa_grup
              ) : (
                '-'
              )
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Instagram</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? (
              data.instagram ? (
                data.instagram
              ) : (
                '-'
              )
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.boxPadding}>
        <Box width="15%">
          <Typography className={classes.contentText}>Facebook</Typography>
        </Box>
        <Box flexShrink={1}>
          <Typography className={classes.contentText}>
            {!loading && !error ? (
              data.facebook ? (
                data.facebook
              ) : (
                '-'
              )
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ItemsTransaction = ({ items }) => {
  const classes = useStyles();

  console.log(items);
  return (
    <Box>
      <Box display="flex">
        <Box p={1} width="20%">
          <img
            src={items.product_photo ? items.product_photo : noImage}
            width="100%"
          />
        </Box>
        <Box p={1} width="40%">
          <Typography className={classes.titleProduct}>
            {items.product_name}
          </Typography>
          <Box display="flex">
            <Box p={1} width="20%">
              <Typography
                className={classes.titleProduct}>{`X ${items.qty}`}</Typography>
            </Box>
            <Box p={1} width="80%" align="right">
              <Typography className={classes.textItems}>
                <NumberFormat number={items.total_price} curency="Rp " />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DetailPrice = ({ id }) => {
  const classes = useStyles();
  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions/fn/detail/${id}`
  );

  const detailQty =
    data && (!loading || !error) ? data.details.map(d => d.qty) : null;

  const totalItem =
    detailQty !== null
      ? detailQty.reduce((a, b) => parseInt(a) + parseInt(b), 0)
      : 0;

  return (
    <Box>
      <Box display="flex" p={1} alignItems="center">
        <Box width="30%">
          <Typography className={classes.textItems}>Total Item</Typography>
        </Box>
        <Box width="70%" align="right">
          <Typography className={classes.textItems}>
            <NumberFormat number={totalItem} />{' '}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        className={classes.dashTopBorder}>
        <Box width="30%">
          <Typography className={classes.textBold}>Total</Typography>
        </Box>
        <Box width="70%" align="right">
          <Typography className={classes.titleProduct}>
            {data && (!loading || !error) ? (
              <NumberFormat number={data.gross_value} curency="Rp " />
            ) : (
              <Skeleton />
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const DetailTransaction = ({ id }) => {
  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/point_transactions_details/fn/list?sort=&page=&limit=&filter=[["transaction_id","_ilike","${id}"]]`
  );

  return (
    <Grid container>
      <Grid item sm={8}>
        {!loading && !error ? (
          data.map((itm, idx) => <ItemsTransaction items={itm} />)
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </Grid>
      <Grid item sm={4}>
        <DetailPrice id={id} />
      </Grid>
    </Grid>
  );
};

const ListTrasaction = ({ data, id }) => {
  const [open, setOpen] = useState(false);
  const [idSelect, setIdSelect] = useState('');

  const OpenModal = e => {
    setOpen(true);
    setIdSelect(e);
  };

  const handleClose = () => {
    setOpen(false);
    setIdSelect(null);
  };

  return (
    <>
      <List
        {...data}
        actions={false}
        pagination={false}
        bulkActionButtons={false}>
        <ReferenceManyField
          target="customer_id"
          reference="point_transactions"
          sort={{ field: 'timestamp', order: 'ASC' }}
          filter={{ customer_id: id, type_id: 'PEMBELIAN' }}
          pagination={<Pagination />}
          perPage={10}>
          <Datagrid rowClick={OpenModal}>
            <FunctionField
              label="Transaction"
              render={record => 'Consumer purchases'}
              style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                display: 'block',
              }}
            />
            <TextField source="product_name" label="Payment" />
            <FunctionField
              label="Date"
              render={record =>
                `${record.tgl_trasaction} ${record.time_trasaction}`
              }
            />
            <FunctionField
              label="Total Price"
              render={record => (
                <NumberFormat number={record.gross_value} curency="Rp " />
              )}
            />
          </Datagrid>
        </ReferenceManyField>
      </List>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Detail Transaction
        </DialogTitle>
        <DialogContent>
          <DetailTransaction id={idSelect} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const ShowCustomer = props => {
  const classes = useStyles();
  const history = useHistory();

  const [{ data, loading, error }, refetch] = useAxios(
    `${process.env.REACT_APP_API_URL}/customers/fn/detail/${props.id}`
  );

  const generateCode = name => {
    const words = name.trim().split(' ');

    const first = words[0].substring(0, 1).toUpperCase();
    const last = words[words.length - 1].substring(0, 1).toUpperCase();

    return `${first}${last}`;
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        className={classes.headerContent}
        style={{ backgroundImage: `url(${profileBg})` }}>
        <Grid container>
          <Grid item sm={6}>
            <Box display="flex" alignItems="center" p={1}>
              <Box p={1}>
                <Avatar className={classes.avatarWrapper}>
                  {!loading && !error ? generateCode(data.name) : <Skeleton />}
                </Avatar>
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography variant="h3" className={classes.title}>
                  {!loading && !error ? data.name : <Skeleton />}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box
              display="flex"
              alignItems="start"
              justifyContent="flex-end"
              m={1}
              p={1}>
              <Box p={4}>
                <Typography variant="h4" className={classes.subtitle}>
                  Member id
                </Typography>
                <Typography variant="h5" className={classes.textHeader}>
                  {!loading && !error ? data.code : <Skeleton />}
                </Typography>
              </Box>
              <Box p={4}>
                <Typography variant="h4" className={classes.subtitle}>
                  Member since
                </Typography>
                <Typography variant="h5" className={classes.textHeader}>
                  {!loading && !error ? data.tgl_join : <Skeleton />}
                </Typography>
              </Box>
              <Box p={4}>
                <Typography variant="h4" className={classes.subtitle}>
                  <img
                    src={pointProfile}
                    width="20"
                    style={{ verticalAlign: 'middle' }}
                  />{' '}
                  Points
                </Typography>
                <Typography
                  variant="h5"
                  className={classes.textHeader}
                  style={{ textAlign: 'right' }}>
                  {!loading && !error ? data.total_point + ' P' : <Skeleton />}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.content}>
        <AppBar
          position="static"
          color="default"
          className={classes.barTabCustom}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            <Tab
              className={classes.fontCapitalize}
              label="Personal Data"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.fontCapitalize}
              label="Transaction"
              {...a11yProps(1)}
            />
            <Tab
              className={classes.fontCapitalize}
              label="History Point"
              {...a11yProps(2)}
            />
            <Tab
              className={classes.fontCapitalize}
              label="Point Redemption"
              {...a11yProps(3)}
            />
          </Tabs>
          <Button
            className={classes.btnPassword}
            onClick={() => history.push(`/users/${data.users_id}`)}>
            Change Password
          </Button>
          <Button
            className={classes.btnExp}
            href={`${process.env.REACT_APP_API_URL}/customers/fn/print/${props.id}`}>
            Export Transaction
          </Button>
        </AppBar>
        <TabPanel value={value} index={0}>
          <DetailCustomer id={props.id} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ListTrasaction data={props} id={props.id} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ListHistory data={props} id={props.id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ListRedem data={props} id={props.id} />
        </TabPanel>
      </Box>
    </>
  );
};

export default ShowCustomer;
