import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTheme } from '@material-ui/styles';
import axios from 'axios';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import React, { useState } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  downloadCSV,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  actionCol: {
    textAlign: 'right',
    display: 'block',
  },
}));

const Filters = [
  <TextInput label="Name" source="name" alwaysOn />,
  <TextInput source="phone" label="No. Hp" alwaysOn />,
  <TextInput source="code" label="Member Id" />,
  <TextInput source="nik" label="NIK" />,
  <TextInput source="area" label="City" />,
  <ReferenceInput
    source="level_id"
    reference="master_customer_levels"
    label="Level Customer">
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

const StatusCustomer = ({ record }) => {
  const theme = useTheme();
  return (
    <Chip
      color="primary"
      style={{
        backgroundColor:
          record.active === '1'
            ? theme.palette.success.main
            : theme.palette.danger.main,
      }}
      label={record.active === '1' ? 'Active' : 'Inactive'}
    />
  );
};

const ActionCustomer = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStatus = () => {
    const newStatus = record.active === '1' ? '0' : '1';
    axios
      .put(`${process.env.REACT_APP_API_URL}/customers/fn/edit/${record.id}`, {
        active: newStatus,
        edit_status: true,
      })
      .then(response => {
        const dt = response.data;

        if (dt) {
          refresh();
          unselectAll('shops');
          notify(
            <Typography style={{ color: '#fff' }}>
              Status customers updated
            </Typography>,
            'success'
          );
        } else {
          notify(
            <Typography style={{ color: '#fff' }}>
              Status customers not updated
            </Typography>,
            'danger'
          );
        }
      });
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateX(10px) translateY(50px)',
          },
        }}>
        <MenuItem
          key="menu-edit"
          onClick={() => history.push(`/customers/${record.id}`)}>
          Edit
        </MenuItem>
        <MenuItem
          key="menu-detail"
          onClick={() => history.push(`/customers/${record.id}/show`)}>
          Detail
        </MenuItem>
        <MenuItem key="menu-activated" onClick={changeStatus}>
          {record.active === '1' ? 'Inactived' : 'Active'}
        </MenuItem>
      </Menu>
    </>
  );
};

const exporter = posts => {
  const postsForExport = posts.map(function (a) {
    return {
      customer_name: a.name,
      member_id: a.code,
      area: a.area,
      join_date: a.join_date,
      customer_level: a.level_id.replace(/_/gi, ' '),
      status: a.active === '1' ? 'Active' : 'Inactive',
    };
  });

  jsonExport(
    postsForExport,
    {
      headers: [
        'customer_name',
        'member_id',
        'area',
        'join_date',
        'customer_level',
        'status',
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, 'member_database'); // download as 'posts.csv` file
    }
  );
};

const ListCustomer = props => {
  const classes = useStyles();
  const location = useLocation();

  const filterItem =
    location.state && location.state.join_date
      ? {
          gte__join_date: moment().format('YYYY-MM-01'),
          lte__join_date: moment().format('YYYY-MM-DD'),
        }
      : location.state && location.state.active
      ? { eq__id_active: true }
      : null;

  return (
    <List
      {...props}
      title="Member Database"
      filters={Filters}
      filter={filterItem}
      exporter={exporter}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="code" label="Member Id" />
        <TextField source="nik" label="NIK" />
        <TextField source="area" label="City" />
        <TextField source="phone" label="No. Hp" />
        <TextField source="join_date" label="Join since" />
        <ReferenceField
          label="Level Customer"
          link={false}
          reference="master_customer_levels"
          source="level_id">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Status"
          render={record => <StatusCustomer record={record} />}
        />
        <FunctionField
          label=""
          className={classes.actionCol}
          render={record => <ActionCustomer record={record} />}
        />
      </Datagrid>
    </List>
  );
};

export default ListCustomer;
